import { useState, useEffect, useCallback } from 'react';

import { useRouter } from 'next/router';

import { authorise } from '../../../helpers/auth/auth';
import SmartSessionStorage from '../../../helpers/storage/SmartSessionStorage';

type TuseForceLoginModalReturn = [
  isModalOpen: boolean,
  openModal: (args?: {
    authTrackingParams?: { [key: string]: string };
    forceShowModal?: boolean;
  }) => void,
  closeModal: () => void,
];

type TuseForceLoginModal = (
  userID: string | undefined,
  sessionStorageKey: string,
  sessionStorageValue: string | number | boolean,
  //  make sure shouldShowModalCB is memoized or declared outside of component because we're using it as a useEffect dependency
  shouldShowModalCB: () => boolean,
) => TuseForceLoginModalReturn;

const useForceLoginModal: TuseForceLoginModal = (
  userID,
  sessionStorageKey,
  sessionStorageValue,
  shouldShowModalCB,
) => {
  const router = useRouter();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const savedSessionStorageValue = SmartSessionStorage.get(sessionStorageKey);
    SmartSessionStorage.remove(sessionStorageKey);
    if (!userID || !savedSessionStorageValue) return;
    if (savedSessionStorageValue === sessionStorageValue) {
      if (shouldShowModalCB()) {
        setIsModalOpen(true);
        return;
      }
      setIsModalOpen(false);
    }

    return () => {
      SmartSessionStorage.remove(sessionStorageKey);
    };
  }, [shouldShowModalCB, sessionStorageKey, sessionStorageValue, userID]);

  const openModal = useCallback(
    async ({ authTrackingParams = {}, forceShowModal = false } = {}) => {
      if (userID || forceShowModal) {
        setIsModalOpen(true);
        return;
      }

      SmartSessionStorage.set(sessionStorageKey, sessionStorageValue);
      await authorise(
        {
          forceLogIn: true,
          redirectBackTo: router.asPath,
          trackingParams: authTrackingParams,
        },
        'daft',
      );
    },
    [router.asPath, sessionStorageKey, sessionStorageValue, userID],
  );

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return [isModalOpen, openModal, closeModal];
};

export { useForceLoginModal };
